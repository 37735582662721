// STATE DEFINITION

const initialState = {
  fetchingSubInfo: false,
  videoAutoAdvance: true,
  videoShowSubtitles: false,
  lastTextTabSelection: "playtext",
  lastKps: {
    raj: null,
    macbeth: null,
    msnd: null,
    hamlet: null,
    jc: null,
  },
  lastChars: {
    raj: null,
    macbeth: null,
    msnd: null,
    hamlet: null,
    jc: null,
  },
  lastSupps: {
    raj: null,
    macbeth: null,
    msnd: null,
    hamlet: null,
    jc: null,
  },
}

// ACTIONS

export const SET_LAST_KP_FOR_PLAY = "SET_LAST_KP_FOR_PLAY"

export const setLastKPForPlay = (playId, keypageId) => ({
  type: SET_LAST_KP_FOR_PLAY,
  playId,
  keypageId,
})

export const SET_LAST_CHAR_FOR_PLAY = "SET_LAST_CHAR_FOR_PLAY"

export const setLastCharForPlay = (playId, charId) => ({
  type: SET_LAST_CHAR_FOR_PLAY,
  playId,
  charId,
})

export const SET_LAST_SUPP_FOR_PLAY = "SET_LAST_SUPP_FOR_PLAY"

export const setLastSuppForPlay = (playId, suppId) => ({
  type: SET_LAST_SUPP_FOR_PLAY,
  playId,
  suppId,
})

export const TOGGLE_VID_AUTO_ADVANCE = "TOGGLE_VID_AUTO_ADVANCE"

export const toggleVidAutoAdvance = () => ({
  type: TOGGLE_VID_AUTO_ADVANCE
})

export const TOGGLE_VID_SHOW_SUBTITLES = "TOGGLE_VID_SHOW_SUBTITLES"

export const toggleVidShowSubtitles = () => ({
  type: TOGGLE_VID_SHOW_SUBTITLES
})

export const SET_LAST_TEXT_TAB_SELECTION = "SET_LAST_TEXT_TAB_SELECTION"

export const setLastTextTabSelection = (tabId) => ({
  type: SET_LAST_TEXT_TAB_SELECTION,
  tabId,
})

export const SET_FETCHING_SUB_INFO = "SET_FETCHING_SUB_INFO"
export const setFetchingSubInfo = (value) => ({
  type: SET_FETCHING_SUB_INFO,
  value
})

const SIBReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_KP_FOR_PLAY:
      var lastKps = state.lastKps
      lastKps[action.playId] = action.keypageId
      return { ...state, lastKps }
    case SET_LAST_CHAR_FOR_PLAY:
      var lastChars = state.lastChars
      lastChars[action.playId] = action.charId
      return { ...state, lastChars }
    case SET_LAST_SUPP_FOR_PLAY:
      var lastSupps = state.lastSupps
      lastSupps[action.playId] = action.suppId
      return { ...state, lastSupps }
    case SET_LAST_TEXT_TAB_SELECTION:
      var lastTextTabSelection = action.tabId
      return { ...state, lastTextTabSelection }
     case TOGGLE_VID_AUTO_ADVANCE:
      var videoAutoAdvance = !state.videoAutoAdvance
      return { ...state, videoAutoAdvance }
    case TOGGLE_VID_SHOW_SUBTITLES:
      var videoShowSubtitles = !state.videoShowSubtitles
      return { ...state, videoShowSubtitles }
    case SET_FETCHING_SUB_INFO:
      var fetchingSubInfo = action.value
      return { ...state, fetchingSubInfo }
     default:
      return state
  }
}

// REDUCER
export default SIBReducer