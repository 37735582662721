import React, { createContext, useContext } from "react"

import { useSubInfo } from "./usesubinfo"

// Create a Context to hold the identity state
const AuthContext = createContext()

// Export hook to access subinfo easily
const useSubInfoContext = () => useContext(AuthContext)

// Top level wrapper around the app to instantiate context
const SIBAuthContext = ({ children }) => {

  const subInfo = useSubInfo()
  return (
    <AuthContext.Provider value={subInfo}>
      {children}
    </AuthContext.Provider>
  )
}

export {
  SIBAuthContext as default,
  useSubInfoContext
}