/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Bulma Framework global scss
import React from "react"
import "./src/scss/scss/main.scss"
import ReduxWrapper from "./src/state/redux_wrapper"
import NetlifyIdentityContext from "./src/services/react-netlify-identity-gotrue"
import SIBAuthContext from "./src/services/subinfocontext"

export const wrapRootElement = props => {
  return (
    <NetlifyIdentityContext url={`${process.env.GATSBY_NETLIFY_IDENTITY_SITE}`}>
      <SIBAuthContext>
        <ReduxWrapper {...props} />
      </SIBAuthContext>
    </NetlifyIdentityContext>
  )
}
