/* eslint-disable no-throw-literal */
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"

// Function using fetch to POST to our API endpoint
const useSubInfoFunctions = () => {
  const identity = useIdentityContext()
  const create = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-create", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const read = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-read", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        if (response.status === 500) {
          throw ({ status: response.status, statusText: response.statusText })
        }
        return response.json()
      })
  }

  const readAll = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfos-read-all", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const byId = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-by-id", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const update = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-update", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const updateProfile = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-update-profile", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const updateStudentToken = async (data) => {
    return identity
      .authorizedFetch("/.netlify/functions/subinfo-update-token", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((response) => {
        return response.json()
      })
  }

  const findStudentCode = async (data, wasRefresh) => {
    //unauthorised, obviously
    var wrap = {
      wasRefresh: wasRefresh,
      data: data,
    }
    return fetch("/.netlify/functions/subinfo-by-student-code", {
      body: JSON.stringify(wrap),
      method: "POST",
    }).then((response) => {
      return response.json()
    })
  }

  return {
    create,
    read,
    readAll,
    byId,
    update,
    updateProfile,
    updateStudentToken,
    findStudentCode,
  }
}

export { useSubInfoFunctions }
