/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { useSubInfoFunctions } from "../stateless/subinfo"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import { navigate } from "gatsby"

const SIB_AUTH_TOKEN_STORAGE_KEY = "sib_auth.authtoken"
const SIB_AUTH_TOKEN_STORAGE_KEY_EXPIRY = "sib_auth.authtoken.expiry"

const useSubInfo = () => {
  const identity = useIdentityContext()
  const subInfoFunctions = useSubInfoFunctions()
  const [subInfo, setSubInfo] = useState()
  const [subInfoLoaded, setSubInfoLoaded] = useState(false)

  const popUserGreet = (subInfoIn) => {
    subInfoIn['userGreet'] = "Welcome!"
    if (subInfoIn.isStudentCode) {
      subInfoIn['userGreet'] = `Welcome, ${subInfoIn.schoolName}!`
    } else if (subInfoIn.metadata) {
      if (subInfoIn.metadata.first_name) {
        subInfoIn['userGreet'] = `Welcome, ${subInfoIn.metadata.first_name}!`
      } else {
        subInfoIn['userGreet'] = `Welcome, ${subInfoIn.metadata.full_name}!`
      }
    } 
    return subInfoIn
  }

  var studentCodeLogin = async (studentCode, wasRefresh) => {
    setSubInfoLoaded(false)
    var tempSubInfo = await subInfoFunctions.findStudentCode(
      {
        studentCode: studentCode.replaceAll("-", "").trim(),
      },
      wasRefresh
    )
    if (tempSubInfo !== {}) {
      localStorage.setItem(
        SIB_AUTH_TOKEN_STORAGE_KEY,
        JSON.stringify(tempSubInfo.code)
      )
      var expiryDate = new Date()
      // Allow student codes to live in storage for 2 days
      expiryDate = expiryDate.setDate(expiryDate.getDate() + 2)

      localStorage.setItem(
        SIB_AUTH_TOKEN_STORAGE_KEY_EXPIRY,
        JSON.stringify(expiryDate)
      )
      setSubInfo(popUserGreet(tempSubInfo))
    }
    setSubInfoLoaded(true)
    return tempSubInfo
  }

  var fetchAuthContext = async () => {
    var newSubInfo = subInfo
    if (!newSubInfo && identity.user) {
      try {
        newSubInfo = await subInfoFunctions.read()
        // assume it's not there and create a record
        if (newSubInfo && !newSubInfo.sub_id) {
          newSubInfo = await subInfoFunctions.create()
          newSubInfo = await subInfoFunctions.read()
        }
      } catch (error) {
        // Any issues here, most likely due to timeout, log out
        identity.logout()
        logout()
        navigate("/login")
        return
      }
    }
    if (newSubInfo) {
      setSubInfo(popUserGreet(newSubInfo))
    }
    setSubInfoLoaded(true)
  }

  var logout = () => {
    localStorage.removeItem(SIB_AUTH_TOKEN_STORAGE_KEY)
    localStorage.removeItem(SIB_AUTH_TOKEN_STORAGE_KEY_EXPIRY)
    setSubInfo(null)
    setSubInfoLoaded(false)
  }

  // Subinfo should load automatically every time the user identity changes
  useEffect(() => {
    setSubInfoLoaded(false)
    if (identity.user) {
      fetchAuthContext()
    } else {
      // see if we have a student string
      const studentCodeString = localStorage.getItem(SIB_AUTH_TOKEN_STORAGE_KEY)
      const studentCodeExpiryDate = new Date(
        JSON.parse(localStorage.getItem(SIB_AUTH_TOKEN_STORAGE_KEY_EXPIRY))
      )
      if (studentCodeString && studentCodeString !== "undefined") {
        if (studentCodeExpiryDate < new Date()) {
          logout()
          navigate("/")
          return
        }
        const studentCode = JSON.parse(studentCodeString)
        if (studentCode) {
          studentCodeLogin(studentCode, true)
        }
      } else {
        //this is a failure to load
        setSubInfoLoaded(true)
      }
    }
  }, [identity.user])

  return {
    subInfo,
    subInfoLoaded,
    studentCodeLogin,
    logout,
  }
}

export { useSubInfo }
